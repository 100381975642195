import React, { useState, useEffect } from "react";
import { cn } from "../lib/helpers";

import * as styles from "./lightswitch.module.css";

const LightSwitch = ({ on, off, isActive = false }) => {

  const handleOnClick = () => {
    if (isActive) {
      off();
    } else {
      on();
    }
  }

  return (
    <button className={cn(styles.root, isActive ? styles.isActive : '')} onClick={() => handleOnClick()}>
      <div></div>
    </button>
  );
};

export default LightSwitch;
